<template>
  <div class="confirm-order">
    <header-t />
    <nav2 />
    <div class="cont">
      <el-card shadow="nerve" class="card">
        <div  v-if="payRadio == 2" class="theSteps">
          <el-steps :active="active" finish-status="success">
            <el-step title="订单确认"></el-step>
            <el-step title="订单提交"></el-step>
            <el-step title="线下打款"></el-step>
          </el-steps>
        </div>
        <div v-else class="theSteps">
          <el-steps :active="active" finish-status="success">
            <el-step title="订单确认"></el-step>
            <el-step title="订单支付"></el-step>
            <el-step title="订单完成"></el-step>
          </el-steps>
        </div> 
        <div class="item">
          <div class="title"><span class="tag"></span>订单信息：</div>
          <el-table
            :data="tableData"
            :header-cell-style="{ background: '#f8f8f8' }"
            :cell-style="needStyle"
            border
            stripe
            align="center"
          >
            <el-table-column type="index" label="序号" width="50" />
            <el-table-column prop="coursePic" width="230">
              <template slot-scope="scope">
                <img :src="scope.row.coursePic" class="img" />
              </template>
            </el-table-column>
            <el-table-column prop="courseName" label="课程名称" />
            <el-table-column prop="coursePrice" label="商品价格">
              <template slot-scope="scope">
                ¥ {{ scope.row.coursePrice }}
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="item">
          <div class="title"><span class="tag"></span>支付方式：</div>
          <div class="pay-cont">
            <el-radio-group v-model="payRadio">
              <el-radio
                v-for="item in payData"
                :key="item.payMethod"
                :label="item.payMethod"
                class="pay-radio"
              >
                <img
                  :src="item.payImg"
                  :class="item.payMethod == payRadio ? 'border' : ''"
                />
              </el-radio>
            </el-radio-group>
          </div>
        </div>
        <div  v-if="payRadio == 2" class="item">
          <div class="title"><span class="tag"></span>购买份数：</div>
          <div class="pay-cont">
            <div class="buy_wrap">
              <div class="buy_tit">数量：</div>
              <div class="buy_cont">
                <el-button @click="handleDec" class="bnt_num" type="primary" :disabled="decrease">-</el-button>
                <el-input v-model="buy_num" :min="1" class="buy_input" :style="inputStyle" @input="validateInput" :precision="2" :step="1"></el-input>
                <el-button @click="handleAdd" class="bnt_num" type="primary" :disabled="add">+</el-button>
              </div>
              <!-- <div class="view-total">
                
              </div> -->
            </div>
          </div>
        </div>
        <!-- <div v-if="payRadio == 2" class="item">
          <div class="title"><span class="tag"></span>收款信息：(请按如下账户打款)</div>
          <el-table
            :data="tableData2"
            class="tableData"
            border
            :header-cell-style="{ background: '#f8f8f8' }"
          >
            <el-table-column prop="accountName" label="账户名" />
            <el-table-column prop="cardNo" label="帐号" />
            <el-table-column prop="bankName" label="开户行" />
          </el-table>
        </div>
        <div v-if="payRadio == 2" class="item">
          <div class="title"><span class="tag"></span>付款信息：</div>
          <div class="pay-cont">
            <el-form
              ref="payForm"
              :model="payForm"
              :rules="payFormRules"
              class="payForm"
              label-width="130px"
            >
              <el-form-item prop="name" label="付款账户名称：">
                <el-input
                  v-model="payForm.name"
                  clearable
                  placeholder="请输入付款账户名称"
                  class="phone"
                  tabindex="1"
                />
              </el-form-item>
              <el-form-item prop="time" label="转账时间：">
                <el-date-picker
                  v-model="payForm.time"
                  type="datetime"
                  placeholder="请选择转账时间"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :picker-options="pickerOptions"
                />
              </el-form-item>
              <el-form-item prop="imgUrl" label="转账截图：">
                <div>
                  <Image-upload
                    :file-arr="payForm.img_url"
                    @getUploadList="getLogoList"
                  />
                  <p class="img-tip">
                    支持JPG\JPEG\PNG格式，不超过5M！
                  </p>
                </div>
              </el-form-item>
              <el-form-item prop="amount" label="订单金额：">
                <el-input
                  v-model="payForm.amount"
                  type="number"
                  clearable
                  placeholder="请输入订单金额"
                  class="phone"
                  tabindex="2"
                /><span class="inpunt-text"> 元</span>
              </el-form-item>
              <el-form-item label="备注：">
                <el-input
                  v-model="payForm.remark"
                  clearable
                  placeholder="请输入备注"
                  class="phone"
                  type="textarea"
                  :rows="3"
                  tabindex="3"
                />
              </el-form-item>
              <div class="btn-wrap">
                <el-button
                  @click="handleOfflinePay"
                  :disabled="isBuging"
                  type="primary"
                  >提交订单</el-button
                >
              </div>
            </el-form>
          </div>
        </div> -->
        <div v-if="payRadio == 1" class="btn-wrap">
          <!-- <img src="https://www.wlmqcol.com/assets/img/order/wechat.png?version=8.8.0"   @click="handlePayMethod(index)" :class="{img}"> -->
          <el-button @click="handleOrder" :disabled="isBuging" type="primary"
            >提交订单</el-button
          >
        </div>
        <div v-if="payRadio == 2" class="btn-wrap" style="text-align:center">
          <el-button
            @click="handleOfflinePay2"
            :disabled="isBuging"
            type="primary"
            >提交订单</el-button
          >
        </div>
      </el-card>
    </div>
    <Footer />
  </div>
</template>

<script>
import HeaderT from "@/components/HeaderT";
import Nav2 from "@/components/Nav2";
import Footer from "@/components/Footer";
import { getClassDetail } from "@/api/product.js";
import { submitOrder, getPayMethod, getTxToken } from "@/api/order";
// import ImageUpload from '@/components/Upload/ImageUpload' // 4-16
// import ImgUpload from "@/components/ImageUpload";
// import uploadDefault from '../../assets/upload_default.png'
export default {
  name: "ConfirmOrder",
  components: {
    HeaderT,
    Nav2,
    Footer,
    // ImageUpload
    // ImgUpload,
  },
  data() {
    return {
      buy_num: 1, // 购买份数
      decrease: false, // 
      add:false, //
      payImage: null, //提交转账截图
      pickerOptions: {},
      payForm: {
        // imgUrl: uploadDefault,
        img_url: [],
      },
      tableData2: [],
      active: 1, // 步骤图激活状态
      isBuging: true,
      index: 0,
      inputStyle: `
        input[type=number]::-webkit-inner-spin-button,
        input[type=number]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      `,
      tableData: [
        /* {
          id: "1",
          courseName: "AA",
          coursePic: "aa",
          coursePrice: 300, // 课程价格
        },
        {
          id: "2",
          courseName: "BB",
          coursePic: "bb",
          coursePrice: 200, // 课程价格
        }, */
      ],
      payData: [
        //  {
        //   payMethod: 1,
        //   payName: '微信支付',
        //   payImg: 'https://www.wlmqcol.com/assets/img/order/wechat@2x.png?version=8.8.0'
        // },
        // {
        //   payMethod: 2,
        //   payName: '线下支付',
        //   payImg: 'https://www.wlmqcol.com/assets/img/order/wechat@2x.png?version=8.8.0'
        // }
      ], //支付方式数据
      payRadio: null,
      totalPrice: 0, // 订单支付金额
      txToken: "", // TxToken
      payFormRules: {
        name: [{ required: true, message: '付款账户名称不能为空', trigger: 'blur' }],
        time: [{ required: true, message: '付款账户名称不能为空', trigger: 'change' }],
        amount: [{ required: true, message: '订单金额不能为空', trigger: 'blur' }]
      },
      cansubmit:true
    };
  },
  watch: {
    payRadio: {
      handler(newVal, oldVal) {
        console.log(newVal, oldVal);
        if (newVal !== null) {
          this.isBuging = false;
        } else {
          this.isBuging = true;
        }
      },
    },
    buy_num: {
      handler(newVal, oldVal) {
        // console.log(newVal, oldVal);
        if (oldVal <= 1) {
          this.decrease = true
        }
        if (newVal <= 1) {
          this.decrease = true
        }else{
          this.decrease = false
        }

      },
      immediate: true
    }
  },
  mounted() {
    this.getPayMethod();
    this.getClassDetail(this.$route.query.id);
  },
  methods: {
    validateInput() {
      const intValue = parseInt(this.buy_num);
      if (isNaN(intValue) || intValue < 1) {
        this.buy_num = 1;
      } else {
        this.buy_num = intValue;
      }
    },
    // 获取支付方式
    getPayMethod() {
      getPayMethod().then((res) => {
        if (res.status === 200) {
          if (res.data.code === 0) {
            this.payData = res.data.data.payMethods;
            this.payRadio = this.payData[0].payMethod;
            this.tableData2.push({
              accountName: res.data.data.offlineBank.accountName,
              bankName: res.data.data.offlineBank.bankName,
              cardNo: res.data.data.offlineBank.cardNo,
            });
          }
        }
      });
    },
    // 那课程详细信息
    getClassDetail(ClassId) {
      getClassDetail(ClassId).then((res) => {
        if (res.data.code === 0) {
          let obj = {
            courseName: res.data.data.courseName,
            coursePic: res.data.data.coursePic,
            coursePrice: res.data.data.coursePrice,
          };
          this.tableData.push(obj);
          this.totalPrice = res.data.data.coursePrice;
        }
      });
    },
    //总价计算
    getTotal(param) {
      const { columns, data } = param;
      // console.log(columns);
      // console.log(data);
      const sums = [];
      // const arr = ["test1","test2","test3","test4"];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "总价";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        // if(arr.indexOf(column.property)!==-1){

        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          // sums[index] += " 元";
          sums[index];
        } else {
          // sums[index] = 'N/A';
          // }
        }
      });
      console.log(sums);
      sums[3] = `¥ ${sums[3]}`;
      return sums;
    },
    needStyle(column) {
      let cellStyle;
      switch (column.column.property) {
        case "test1":
          cellStyle = "color:red";
          break;
        case "test2":
          cellStyle = "color:red";
          break;
        case "test3":
          cellStyle = "color:blue";
          break;
        default:
          cellStyle = "";
      }
      return cellStyle;
    },
    // 处理提交订单的数据
    handlerData() { // 0 线上付款，1 线下付款
      let tmpObj = this.payData[this.payRadio];
      console.log(tmpObj);
      let arr = [];
      console.log(arr.push(tmpObj));
      let obj2 = null
      const obj = {
        productName: this.tableData[0].courseName, //产品名（填写课程名）
        productId: Number(this.$route.query.id), //产品Id（填写课程Id）
        productType: 1, //产品类型 1 课程（目前先写死）
        quantity: this.buy_num, // 
        payDetail: {
          payMethod: this.payRadio, //支付方式 1 微信支付
          payPrice: this.totalPrice * this.buy_num, //支付金额
          productPrice: this.totalPrice, //产品单价
          // subjectId: 'a' //可选	如使用优惠券等，填写优惠券ID
          /* offlineAccountName: this.payRadio === 2 ? this.payForm.name : '',
          offlinePayTime: this.payRadio === 2 ? this.payForm.time : '',
          offlineImg: this.payRadio === 2 ? this.payImage[0] : '', */
        },
        txToken: this.txToken,
        amount: this.payForm.amount,
        remark: this.payForm.remark
      };
      obj2 = this.payRadio == 2 ? Object.assign(obj, { quantity: this.buy_num}) : obj
      this.isBuging = true;
      submitOrder(obj2).then((res) => {
        console.log(res);
        this.cansubmit = true;
        if (res.data.code === 0) {
          const tmpUrl = this.payRadio===1 ? `/confirm/payment?orderId=${res.data.data.orderId}&courseId=${this.$route.query.id}` : '/entCenter/orderlistManagement'
          if(this.payRadio === 2) {
            this.$message({
              type: 'success',
              message: '订单提交成功！',
              duration: 500,
              onClose: ()=>{
                this.$router.push({
                  path: tmpUrl,
                });
              }
            })
          }else {
            this.$router.push({
              path: tmpUrl,
            });
          }
        } else {
          this.cansubmit = true;
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
      });
    },
    //提交订单
    async handleOrder() {
      if(this.cansubmit){
        await this.getTxToken();
        this.handlerData()
      }
     
    },
    // 获取getTxToken
    getTxToken() {
      this.cansubmit = false;
      return new Promise((resolve) => {
        const obj = {
          tokenType: 1, //1 订单提交token
        };
        getTxToken(obj).then((res) => {
          if (res.data.code === 0) {
            console.log(res);
            this.txToken = res.data.data.txToken;
            resolve();
          }else{
            this.cansubmit = true;
          }
        });
      });
    },
    // 选择支付方式
    handlePayMethod() {},
    //线下支付
    async handleOfflinePay() {
      console.log("线下支付提交....");
      console.log(this.payImage);
      if(this.payImage === null || this.payImage === undefined) {
        this.$message({
          type: 'error',
          message: '请上传转账凭证截图'
        })
        return
      }else{
        await this.getTxToken()
        this.$refs.payForm.validate((vaild) => {
          if(vaild){
            this.handlerData()
          }else{
            console.log('nopass');
          }
        })
      }
      
    },
    // 新
    handleOfflinePay2() {
      // await this.getTxToken()
      this.handleOrder()
    },




    //
    /* fileList(val) {
      console.log('hahaha:', val);
      this.payImage = val
    }, */
    // 获取收集点门面照片
    getLogoList(val) {
      this.payForm.img_url = val
      this.payImage = this.payForm.img_url
    },
    //
    handleAdd() {
      this.buy_num = this.buy_num + 1
    },
    handleDec() {
      if(this.buy_num > 1)
      this.buy_num = this.buy_num - 1
    }
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.confirm-order {
  height: 100%;
  background-color: #f5f5f5;
  .cont {
    width: 1170px;
    margin: 20px auto;
    .card {
      .theSteps {
        text-align: left;
      }
    }
  }
  .item {
    margin-top: 20px;
    .title {
      font-size: 14px;
      color: #333;
      font-weight: bold;
      text-align: left;
      margin: 40px 0 20px 0;
      border-bottom:1px #ebebeb solid;
      padding-bottom: 8px;
      .tag{
        border-left:3px solid #409EFF;
        margin-right: 10px;
      }
    }
    .img {
      width: 200px;
      height: 150px;
    }
    .PayMethod {
      font-size: 14px;
      font-weight: bold;
      text-align: left;
    }
    .pay-cont {
      text-align: left;
      margin-top: 20px;
      .pay-radio {
        // display: flex;
      }
      img {
        border: none;
        padding: 3px;
        border: 2px #f5f5f5 solid;
        width: 150px;
        height: 50px;
        margin-top: 0;
      }
      .border {
        border: 1px #43bc60 solid;
      }
      /deep/ .el-radio__input {
        display: none;
      }
      .payForm {
        width: 600px;
        .phone {
          width: 300px;
        }
      }
      .buy_wrap{
        display: flex;
        width: 500px;
        // border:1px #f00 solid;
        .buy_tit{
          line-height: 40px;
          font-size: 14px;
        }
        .buy_cont{

          .buy_input{
            width: 70px;
            /* text-align: center; */
            margin: 0 10px;
          }
          .bnt_num{
            /* width: 40px; */
          }
        }
        
      }
    }
    img {
      float: left;
      margin-top: 5px;
      border: 1px solid #eee;
      padding: 5px;

    }
    .el-button--medium {
      // margin-top: 50px;
    }
    /* .el-table tbody tr:hover>td { 
      background-color:#ffffff!important
    }
    .el-table__footer-wrapper tbody tr:hover > td { 
      background-color:#ebebeb!important;
    }
    .el-table__footer-wrapper tbody tr td { 
      color:#f00
    } */
  }
  .btn-wrap {
    margin-top: 20px;
    text-align: right;
  }
}
</style>